<template>
    <div :class="{ 'timeline-dark': isDark }">
		<p class="font-weight-medium">{{ $t('subheader.application-steps') }}</p>
        <div class="timeline-container">
            <div 
                v-if="showLeftIndicator" 
                class="scroll-indicator scroll-indicator--left"
                @click="scrollLeft"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </div>
            <div class="timeline-wrapper" ref="timelineWrapper" @scroll="checkScroll">
                <div class="timeline-steps">
                    <div
                        v-for="(step, index) in formattedSteps"
                        :key="index"
                        class="timeline-step"
                        :class="{ 'completed': index < formattedSteps.length }"
                    >
                        <div class="timeline-dot" :class="`pipeline-${step.pipeline}`">
                            <v-icon small>{{ getPipelineIcon(step.pipeline) }}</v-icon>
                        </div>
                        <v-card class="elevation-2 mx-2 timeline-card">
                            <v-card-text>
                                <div class="font-weight-medium">{{ step.title }}</div>
                                <div class="text-caption">
                                    {{ step.date }}
                                </div>
                                <div class="text-caption text--secondary">
                                    {{ $t(`tab.${step.pipeline}`) }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </div>
            <div 
                v-if="showRightIndicator" 
                class="scroll-indicator scroll-indicator--right"
                @click="scrollRight"
            >
                <v-icon>mdi-chevron-right</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CandidateApplicationSteps',
  
  data() {
    return {
      showLeftIndicator: false,
      showRightIndicator: false
    }
  },

  props: {
    application: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      isDark: state => state.currentUser.isDark
    }),
    applicationSteps() {
      if (!this.application?.steps) return []
      return this.application?.steps || []
    },

    formattedSteps() {
      return this.applicationSteps.map(step => ({
        title: step.status,
        date: this.formatDate(step.date),
        pipeline: step.pipeline,
        color: this.getTimelineColor(step.pipeline)
      }))
    }
  },

  mounted() {
    this.checkScroll()
    window.addEventListener('resize', this.checkScroll)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScroll)
  },

  methods: {
    checkScroll() {
      const wrapper = this.$refs.timelineWrapper
      if (wrapper) {
        this.showLeftIndicator = wrapper.scrollLeft > 0
        this.showRightIndicator = wrapper.scrollLeft < (wrapper.scrollWidth - wrapper.clientWidth)
      }
    },

    scrollRight() {
      const wrapper = this.$refs.timelineWrapper
      if (wrapper) {
        wrapper.scrollBy({
          left: 200,
          behavior: 'smooth'
        })
      }
    },

    scrollLeft() {
      const wrapper = this.$refs.timelineWrapper
      if (wrapper) {
        wrapper.scrollBy({
          left: -200,
          behavior: 'smooth'
        })
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    },

    getPipelineIcon(pipeline) {
      const icons = {
        lead: 'verified',
        applicant: 'person_pin',
        interview: 'person_search',
        archive: 'archive'
      }
      return icons[pipeline] || 'help'
    },

    getTimelineColor(pipeline) {
      const colors = {
        lead: '#FF9800',
        applicant: '#1976D2',
        interview: '#9C27B0',
        archive: '#757575'
      }
      return colors[pipeline] || '#9E9E9E'
    }
  },
}
</script>

<style scoped>
.timeline-container {
    position: relative;
}

.timeline-wrapper {
    overflow-x: auto;
    padding: 20px 0;
    scroll-behavior: smooth;
}

.timeline-steps {
    display: flex;
    align-items: flex-start;
    min-width: max-content;
    position: relative;
    padding: 0 40px;
}

.timeline-steps::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 0;
    right: 32px;
    height: 2px;
    background: #E0E0E0;
    z-index: 0;
}

.timeline-step {
    flex: 0 0 auto;
    margin-right: 32px;
    position: relative;
    min-width: 200px;
    z-index: 1;
}

.timeline-step:last-child {
    margin-right: 0;
}

.timeline-step::before {
    display: none;
}

.timeline-dot {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.timeline-dot .v-icon {
    color: white;
    font-size: 14px;
}

.pipeline-lead {
    background-color: #FF9800;
}

.pipeline-applicant {
    background-color: #1976D2;
}

.pipeline-interview {
    background-color: #9C27B0;
}

.pipeline-archive {
    background-color: #757575;
}

.v-card {
    margin-top: 40px;
    width: 100%;
}

.timeline-wrapper::-webkit-scrollbar {
    display: none;
}

.timeline-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-indicator {
    position: absolute;
    top: 16px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s;
    z-index: 2;
}

.scroll-indicator--left {
    left: 0;
}

.scroll-indicator--right {
    right: 0;
}

.scroll-indicator:hover {
    background: rgba(0, 0, 0, 0.2);
}

.scroll-indicator .v-icon {
    color: white;
}

.timeline-container::before,
.timeline-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    pointer-events: none;
}

.timeline-container::before {
    left: 0;
    background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.timeline-container::after {
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
}

.timeline-dark {
    background-color: var(--v-surface-base);
    color: var(--v-high-emphasis-base);
}

.timeline-dark .timeline-steps::before {
    background: #424242;
}

.timeline-dark .scroll-indicator {
    background: rgba(255, 255, 255, 0.1);
}

.timeline-dark .scroll-indicator:hover {
    background: rgba(255, 255, 255, 0.2);
}

.timeline-dark .timeline-container::before {
    background: linear-gradient(to right, var(--v-surface-base), transparent);
}

.timeline-dark .timeline-container::after {
    background: linear-gradient(to left, var(--v-surface-base), transparent);
}

.timeline-container::before {
    background: linear-gradient(to right, var(--v-background-base, #fff), transparent);
}

.timeline-container::after {
    background: linear-gradient(to left, var(--v-background-base, #fff), transparent);
}

.scroll-indicator {
    background: rgba(0, 0, 0, 0.1);
}

.scroll-indicator:hover {
    background: rgba(0, 0, 0, 0.2);
}

.scroll-indicator .v-icon {
    color: var(--v-high-emphasis-base, white);
}

.timeline-steps::before {
    background: var(--v-border-base, #E0E0E0);
}

.timeline-card {
    margin-top: 40px;
    width: 100%;
    border: 1px solid var(--v-border-base);
    transition: all 0.3s ease;
}

.timeline-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.timeline-dark .timeline-card {
    background-color: var(--v-surface-variant-base);
    border-color: var(--v-border-variant-base);
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.10) !important;
}

.timeline-dark .timeline-card:hover {
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.15) !important;
}

.timeline-dark .text--secondary {
    color: var(--v-medium-emphasis-base) !important;
}
</style>
