<template>
	<div>
		<ValidationObserver
			ref="form"
			tag="form"
			@submit="handleValidate"
			id="form"
		>
			<v-container fluid>
				<v-row>
					<v-col v-if="isErrorVisible">
						<v-alert type="error" outlined dense>
							<div>
								{{ $t('err.unexpected-resume-parsing') }}
							</div>
							<div style="margin-top: 0.5rem">
								<v-btn color="secondary" small @click="handleParseRefreshClick">
									<v-icon left>refresh</v-icon>
									{{ $t('button.refresh') }}
								</v-btn>
							</div>
						</v-alert>
					</v-col>
				</v-row>

				<v-row v-if="requestMethod === 'post'">
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<v-checkbox
									v-model="candidate.is_lead"
									:label="`${$t('label.is-lead')}`"
									:error-messages="errors"
									dense
									hide-details
									:false-value="false"
									:true-value="true"
									style=""
								></v-checkbox>
							</fieldset>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.name"
								:label="`${$t('label.full-name')}*`"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="name"
								dense
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.current_company"
								:label="`${$t('label.current-company')}`"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="name"
								dense
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.last_school"
								:label="`${$t('label.last-school')}`"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="name"
								dense
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.total_years_experience"
								:label="$t('label.total-years-experience')"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="total_years_experience"
								dense
								type="number"
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.linkedin_profile"
								:label="$t('label.linkedin-profile')"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="linkedin_profile"
								dense
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row v-if="company.is_secretcv_integration_enabled">
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.secretcv_profile"
								:label="$t('label.secretcv-profile')"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="secretcv_profile"
								dense
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.email-addresses') }}</legend>
							<div
								v-for="(item, index) in candidate.email"
								:key="`email_${index}`"
							>
								<v-row>
									<v-col>
										<ValidationProvider
											rules="required|email"
											v-slot="{ errors }"
										>
											<v-text-field
												v-model="candidate.email[index]"
												:label="`${$t('label.email')}*`"
												flat
												hide-details
												outlined
												:error-messages="errors"
												:name="`email_${index}`"
												dense
												@blur="
													() =>
														handleEmailBlur({
															index,
														})
												"
											></v-text-field>
										</ValidationProvider>
									</v-col>
									<v-col cols="auto">
										<v-btn
											icon
											@click="
												() =>
													handleDeleteEmailAddress({
														index,
													})
											"
											><v-icon>delete</v-icon></v-btn
										>
									</v-col>
								</v-row>
								<v-row v-if="emailsExist[index] && emailsExist[index].exists">
									<v-col>
										<v-alert
											dense
											outlined
											type="error"
											style="margin-bottom: 0"
										>
											<v-row v-if="requestMethod === 'post'">
												<v-col class="grow">
													<div
														v-html="
															$t('err.email_in_use_v1', {
																email: emailsExist[index].email,
															})
														"
													></div>
												</v-col>
											</v-row>
											<v-row v-else>
												<v-col class="grow">
													<div
														v-html="
															$t('err.email_in_use_v2', {
																email: emailsExist[index].email,
															})
														"
													></div>
												</v-col>
												<v-col class="shrink">
													<v-btn
														color="error"
														@click="
															() =>
																handleMergeCandidatesClick({
																	candidatesToMerge:
																		emailsExist[index].candidate_ids_to_merge,
																})
														"
														>{{ $t('button.merge-candidates') }}</v-btn
													>
												</v-col>
											</v-row>
										</v-alert>
									</v-col>
								</v-row>

								<v-row
									v-if="
										emailsExist[index] &&
										emailsExist[index].exists &&
										emailsExist[index].blacklisted
									"
								>
									<v-col>
										<v-alert
											dense
											outlined
											type="error"
											style="margin-bottom: 0"
											icon="error"
										>
											<v-row>
												<v-col>
													<div v-html="$t('err.candidate_blacklisted')"></div>
												</v-col>
											</v-row>
										</v-alert>
									</v-col>
								</v-row>
							</div>
							<v-row>
								<v-col>
									<v-btn small @click="handleAddEmailAddress"
										><v-icon left>add_circle</v-icon
										>{{ $t('button.add') }}</v-btn
									>
								</v-col>
							</v-row>
						</fieldset>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.phone-numbers') }}</legend>
							<v-row
								v-for="(phone, index) in candidate.phone"
								:key="`phone_${index}`"
							>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="candidate.phone[index]"
											:label="`${$t('label.phone')}*`"
											flat
											hide-details
											outlined
											:error-messages="errors"
											:name="`phone_${index}`"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
								<v-col cols="auto">
									<v-btn
										icon
										@click="
											() =>
												handleDeletePhoneNumber({
													index,
												})
										"
										><v-icon>delete</v-icon></v-btn
									>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-btn small @click="handleAddPhoneNumber"
										><v-icon left>add_circle</v-icon
										>{{ $t('button.add') }}</v-btn
									>
								</v-col>
							</v-row>
						</fieldset>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-autocomplete
								v-model="candidate.tags"
								:items="tags"
								item-text="name"
								item-value="id"
								outlined
								dense
								chips
								small-chips
								multiple
								:label="`${$t('label.tags')}`"
								hide-details
								:error-messages="errors"
								deletable-chips
							></v-autocomplete>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row v-if="requestMethod === 'post'">
					<v-col>
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-text-field
								v-model="candidate.source"
								:label="$t('label.source')"
								flat
								hide-details
								outlined
								:error-messages="errors"
								name="source"
								dense
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row
					v-if="
						candidate.work_experience && candidate.work_experience.length > 0
					"
				>
					<v-col>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.work-experience') }}</legend>
							<v-row
								v-for="(
									work_experience_item, index
								) in candidate.work_experience"
								:key="`workExperience_${index}`"
							>
								<v-col cols="12">
									<div style="font-weight: 700">
										{{ work_experience_item.job_title }}
									</div>
									<div>
										{{ work_experience_item.organization }}
									</div>
									<div
										style="font-size: 0.75rem"
										v-if="
											work_experience_item.start_date ||
											work_experience_item.end_date
										"
									>
										<span>{{
											work_experience_item.start_date
												? formatDate({
														timestamp: work_experience_item.start_date,
														format: 'MMM yyyy',
												  })
												: ''
										}}</span>
										<span> - </span>
										<span>
											<span v-if="work_experience_item.is_current">
												Present
											</span>
											<span v-else>
												{{
													work_experience_item.end_date
														? formatDate({
																timestamp: work_experience_item.end_date,
																format: 'MMM yyyy',
														  })
														: $t('label.present')
												}}
											</span>
										</span>
									</div>
									<div>
										{{ work_experience_item.job_description }}
									</div>
								</v-col>
								<v-col
									cols="12"
									v-if="index < candidate.work_experience.length - 1"
								>
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</fieldset>
					</v-col>
				</v-row>

				<v-row v-if="candidate.education && candidate.education.length > 0">
					<v-col>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.education') }}</legend>
							<v-row
								v-for="(educationItem, index) in candidate.education"
								:key="`education_${index}`"
							>
								<v-col cols="12">
									<div style="font-weight: 700">
										{{ educationItem.organization }}
									</div>
									<div>{{ educationItem.education }}</div>
									<div
										style="font-size: 0.75rem"
										v-if="educationItem.start_date || educationItem.end_date"
									>
										<span>{{
											educationItem.start_date
												? formatDate({
														timestamp: educationItem.start_date,
														format: 'MMM yyyy',
												  })
												: ''
										}}</span>
										<span> - </span>
										<span>{{
											educationItem.end_date?.toLowerCase()
												? formatDate({
														timestamp: educationItem.end_date,
														format: 'MMM yyyy',
												  })
												: $t('label.present')
										}}</span>
									</div>
								</v-col>
								<v-col cols="12" v-if="index < candidate.education.length - 1">
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</fieldset>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<LinkQuestionsForm
							:candidate="candidate"
						></LinkQuestionsForm>
					</v-col>
				</v-row>
			</v-container>
		</ValidationObserver>

		<MergeCandidates
			v-if="isMergeCandidatesVisible"
			:candidates="candidatesToMerge"
			:mergeInto="candidateToMergeInto"
			:onClose="handleMergeCandidatesClose"
		></MergeCandidates>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LinkQuestionsForm from '../../../linkQuestionsForm/LinkQuestionsForm';
import MergeCandidates from '../../../mergeCandidates/MergeCandidates';
import api from '@/api';

export default {
	name: 'AutofillCandidateItem',
	components: {
		ValidationObserver,
		ValidationProvider,
		LinkQuestionsForm,
		MergeCandidates,
	},
	props: {
		requestMethod: {
			type: String,
			required: true,
		},
		fileId: {
			type: [String, Number],
			required: false,
		},
		jobId: {
			type: [String, Number],
			required: false,
		},
		candidateProps: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
	},
	computed: {
		...mapState({
			tags: state => state.tags.items,
			linkQuestions: state => state.company.applicationForm.link_questions,
      company: state => state.company.company,
		}),
	},
	data() {
		return {
			format,
			candidate: {
				job_id: null,
				is_lead: true,
				name: null,
				current_company: null,
				last_school: null,
				linkedin_profile: null,
        secretcv_profile: null,
				tags: [],
				email: [],
				phone: [],
				work_experience: [],
				education: [],
				total_years_experience: 0,
				source: null,
				link_questions: [],
				applications: []
			},
			emailsExist: [],
			isMergeCandidatesVisible: false,
			candidatesToMerge: [],
			candidateToMergeInto: null,
			isErrorVisible: false,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			if (!timestamp) {
				return '';
			}

			try {
				const dateToFormat = new Date(timestamp);
				return this.format(dateToFormat, format);
			} catch (error) {
				return '';
			}
		},
		handleParseAndAutofill({ fileId }) {
			this.isErrorVisible = false;
			api.files.parse.post({ id: fileId }).then(
				response => {
					const { data } = response.data;
					this.handleAutofillCandidateProps(data);
					this.isErrorVisible = false;
				},
				() => {
					this.isErrorVisible = true;
				},
			);
		},
		async handleValidate() {
			return await this.$refs.form.validate();
		},
		handleGetCandidateItem() {
			if (this.requestMethod === 'put') {
				return {
					...this.candidate,
					id: this.candidate_id,
				};
			} else if (this.requestMethod === 'post') {
				return {
					...this.candidate,
					files: this.fileId ? [{ id: parseInt(this.fileId, 10) }] : [],
					email:
						this.candidate.email && this.candidate.email.length > 0
							? this.candidate.email[0]
							: null,
					phone:
						this.candidate.phone && this.candidate.phone.length > 0
							? this.candidate.phone[0]
							: null,
					job_id: this.jobId,
				};
			}
		},
		handleAddEmailAddress() {
			this.candidate = {
				...this.candidate,
				email: [...this.candidate.email, null],
			};
			this.emailsExist = [
				...this.emailsExist,
				{ exists: false, email: null, blacklisted: false },
			];
		},
		handleDeleteEmailAddress({ index }) {
			this.candidate = {
				...this.candidate,
				email: this.candidate.email.filter(
					(item, itemIndex) => index !== itemIndex,
				),
			};
			this.emailsExist = this.emailsExist.filter(
				(item, itemIndex) => index !== itemIndex,
			);
		},
		handleAddPhoneNumber() {
			this.candidate = {
				...this.candidate,
				phone: [...this.candidate.phone, null],
			};
		},
		handleDeletePhoneNumber({ index }) {
			this.candidate = {
				...this.candidate,
				phone: this.candidate.phone.filter(
					(item, phoneIndex) => index !== phoneIndex,
				),
			};
		},
		async handleAutofillCandidateProps({
			name,
			emails,
			phoneNumbers,
			workExperience,
			education,
			linkedin,
			totalYearsExperience,
			currentCompany,
			lastSchool,
		}) {
			this.candidate = {
				...this.candidate,
				name: name,
				current_company: currentCompany,
				last_school: lastSchool,
				email: emails || [],
				phone: phoneNumbers || [],
				linkedin_profile: linkedin,
				work_experience:
					workExperience && workExperience.length > 0
						? workExperience.map(
								({
									jobTitle,
									jobDescription,
									organization,
									locationCity,
									locationCountry,
									monthsInPosition,
									startDate,
									endDate,
									isCurrent,
								}) => {
									return {
										start_date: startDate,
										end_date: endDate,
										months_in_position: monthsInPosition || 0,
										is_current: isCurrent,
										location_city: locationCity,
										location_country: locationCountry,
										job_title: jobTitle ? jobTitle : null,
										job_description: jobDescription,
										organization: organization,
									};
								},
						  )
						: [],
				education:
					education && education.length > 0
						? education.map(
								({ education, level, startDate, endDate, organization }) => {
									return {
										start_date: startDate,
										end_date: endDate,
										location_city: '',
										location_country: '',
										education: education,
										level: level,
										organization: organization,
									};
								},
						  )
						: [],
				total_years_experience: totalYearsExperience,
			};

			if (emails && emails.length > 0) {
				this.emailsExist = await Promise.all(
					emails.map(async (emailItem, emailItemIndex) => {
						return await this.handleCheckEmail({
							index: emailItemIndex,
						});
					}),
				);
			}
		},
		async handleEmailBlur({ index }) {
			const isEmailInUse = await this.handleCheckEmail({ index });
			this.emailsExist = this.candidate.email.map(
				(emailItem, emailItemIndex) => {
					if (index !== emailItemIndex) {
						return {
							exists: false,
							email: null,
							blacklisted: false,
						};
					} else {
						return { ...isEmailInUse };
					}
				},
			);
		},
		async handleCheckEmail({ index }) {
			return new Promise(resolve => {
				const email = this.candidate.email[index];
				const { id } = this.candidate;
				if (email) {
					api.applicants.checkEmail
						.get({ email, candidate_id: id })
						.then(function (response) {
							const { items } = response.data;
							if (items.length > 0) {
								resolve({
									exists: true,
									email,
									blacklisted: Boolean(
										items
											.reduce((acc, item) => {
												return [...acc, ...item.applications];
											}, [])
											.find(
												item =>
													item.is_archived &&
													item.archive_status === 'blacklist',
											),
									),
									candidate_ids_to_merge: items.map(({ id }) => id),
								});
							} else {
								resolve({
									exists: false,
									email: null,
									blacklisted: false,
								});
							}
						});
				} else {
					resolve({ exists: false, email: null, blacklisted: false });
				}
			});
		},
		handleMergeCandidatesClose() {
			this.isMergeCandidatesVisible = false;
		},
		handleMergeCandidatesClick({ candidatesToMerge = [] }) {
			this.candidateToMergeInto = this.candidate.id;
			this.candidatesToMerge = candidatesToMerge;
			this.isMergeCandidatesVisible = true;
		},
		handleParseRefreshClick() {
			const { fileId } = this;
			this.handleParseAndAutofill({ fileId });
		},
	},
	mounted() {
		const { fileId, requestMethod, candidateProps } = this;

		if (requestMethod === 'post' && fileId) {
			this.handleParseAndAutofill({ fileId });
		} else if (requestMethod === 'put' && fileId) {
			this.candidate = {
				...this.candidate,
				...candidateProps,
			};

			this.handleParseAndAutofill({ fileId });
		} else if (requestMethod === 'put') {
			this.candidate = {
				...this.candidate,
				...candidateProps,
			};
		}
	},
	watch: {
		linkQuestions: {
			handler: function (newVal) {
				if (newVal) {
					this.candidate = {
						...this.candidate,
						link_questions: JSON.parse(JSON.stringify(newVal)),
					};
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
