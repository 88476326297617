<template>
  <v-container>
    <v-row v-if="company.is_secretcv_integration_enabled">
      <v-col>
        <fieldset class="talentics-fieldset">
          <legend>
            Apply with SecretCV
          </legend>
          <v-container>

            <v-row>
              <v-col>
                <fieldset class="talentics-fieldset talentics-fieldset-paddings">
                  <legend>
                    {{ form.is_secretcv_integration_enabled ? $t('label.enabled') : $t('label.disabled') }}
                  </legend>
                  <v-switch
                    v-model="form.is_secretcv_integration_enabled"
                    color="success"
                    class="v-input--template-switch"
                    hide-details
                    inset
                    dense
                    @change="(newVal) => onJobPropChange({ propName: 'is_secretcv_integration_enabled', newVal: Boolean(newVal) })"
                  ></v-switch>
                </fieldset>
              </v-col>
            </v-row>

            <v-row v-if="form.is_secretcv_integration_enabled">
              <v-col>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="form.secretcv_job_url"
                    label="Job URL"
                    persistent-placeholder
                    placeholder="https://www.secretcv.com/company/position-title-012345"
                    name="name"
                    type="text"
                    outlined
                    flat
                    dense
                    hide-details
                    :error-messages="errors"
                    @input="(newVal) => onJobPropChange({ propName: 'secretcv_job_url', newVal })"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row v-if="form.is_secretcv_integration_enabled">
              <v-col>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="form.secretcv_job_id"
                    label="Job ID"
                    persistent-placeholder
                    placeholder="012345"
                    name="name"
                    type="text"
                    outlined
                    flat
                    dense
                    hide-details
                    :error-messages="errors"
                    @input="(newVal) => onJobPropChange({ propName: 'secretcv_job_id', newVal })"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

          </v-container>
        </fieldset>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';

export default {
  name: 'JobIntegrations',
  components: {
		ValidationProvider,
  },
	computed: {
		...mapState({
      company: state => state.company.company,
		}),
  },
  props: {
		job: {
			type: Object,
			required: true,
		},
    onJobPropChange: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      form: {
        is_secretcv_integration_enabled: false,
        secretcv_job_url: null,
        secretcv_job_id: null,
      },
    };
  },
  watch: {
    job: {
			handler: function (newVal, oldVal) {
				if (
          (newVal && !oldVal) ||
          (newVal && oldVal && (JSON.stringify(newVal) !== JSON.stringify(oldVal)))
        ) {
					const {
            is_secretcv_integration_enabled,
            secretcv_job_url,
            secretcv_job_id,
          } = newVal;
          this.form = {
            ...this.form,
            is_secretcv_integration_enabled: is_secretcv_integration_enabled,
            secretcv_job_url: secretcv_job_url,
            secretcv_job_id: secretcv_job_id,
          };
				}
			},
			immediate: true,
			deep: true,
		},
  }
};
</script>
